.device-settings {
  margin: auto;
  padding-bottom: 20px;
  .test-sound-container {
    margin-bottom: 20px;
    margin-left: -10px;
  }
  .device-select-container {
    width: 275px;
    margin-bottom: 20px;
    #microphone-select {
      margin-bottom: 20px;
    }
  }
}
