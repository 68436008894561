.self-view {
  box-sizing: border-box;
  height: 120px;
  width: 224px;
  border: 1px solid #b0b0b0;
  background-color: #464646;
  color: #b0b0b0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  .placeholder {
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  #self-view-render {
    width: 100%;
    height: 100%;

    video.desktop-video-tile {
      width: 100%;
      height: 100%;
      display: none;
    }
    video[id].desktop-video-tile {
      display: block;
    }
  }
  &.off {
    #self-view-render {
      display: none;
    }
    .placeholder {
      display: flex;
    }
  }
}
