.main {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorButton {
  background-color: red !important;
}

.baseButton {
  width: 80% !important;
  padding: 0.2em;
  margin-top: 1em !important;
}

.errorMessage {
  margin: 1em 0 !important;
}

.errorStack {
  background-color: rgb(24, 6, 5) !important;
  width: 80%;
}

.content {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alertContent {
  margin: 1em 0;
  width: 80%;
}
