.main {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0 1.5rem;
  flex-grow: 1;
  margin: 0;
  list-style: none;
}

.main > :first-child {
  margin-top: auto !important;
  /* use !important to prevent breakage from child margin settings */
}

.typing {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  font-size: 0.7rem;
  font-weight: 600;
}

.typingStatus {
  margin-left: 0.3rem;
  opacity: 0.5;
}

.loadMore {
  display: flex;
  justify-content: center;
}

.loadMessages {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  width: 100%;
  color: #fff;
}

.message {
  display: block;
  flex-direction: column;
  margin-top: 0.2rem;
  max-width: 95%;
  word-wrap: break-word;
  cursor: pointer;
}

.messageContent {
  position: relative;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  cursor: auto;
  img {
    max-width: 300px;
    max-height: 300px;
  }

  .edited + .messageBody {
    display: inline-block;
  }
  .edited {
    font-size: 0.7rem;
    opacity: 0.8;
    margin-right: 8px;
  }

  blockquote {
    background-color: var(--blockquoteBackColor);
    border: 1px solid var(--blockquoteBorder);
    border-left: 3px solid var(--blockquoteBorder);
    border-radius: 4px;
    font-size: 14px;
    span,
    i {
      color: var(--blockquoteColor);
      font-weight: bold;
      font-style: normal;
    }
    a {
      word-wrap: break-word;
      margin-right: 5px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    > div {
      margin-top: 5px;
    }
  }
}

.messageEventContentContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.isSending {
  opacity: 0.8;
}

.redacted {
  font-size: 0.7rem;
  opacity: 0.8;
}

.roomEvent {
  display: flex;
  font-size: 0.7rem;
  opacity: 0.7;
}

.messageMemberInfoContainer {
  display: flex;
  align-items: center;
}

.messageInfoContainer {
  display: flex;
  align-items: center;
  cursor: auto;
}

.messageInfo {
  display: flex;
  margin: 0.5rem 0.5rem 1.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.dateSeperator {
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(216, 216, 232, 1);
  top: 0;
  margin: 3rem 0 0 0;
}

.dateSeperatorText {
  display: flex;
  justify-content: center;
  top: 0;
  font-size: 0.75rem;
  margin: 0rem 0 1rem 0;
}

.messageInfoDate {
  opacity: 0.6;
}

.memberMessage {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .messageInfoContainer {
    margin-left: calc(46px + 13px);
  }

  &:hover {
    .memberActionsContainer {
      opacity: 1;
    }
  }
}

.memberMessageContent {
  margin-left: calc(46px + 13px);
  padding: 10px;
  border-radius: 8px;
  ul,
  ol {
    padding-left: 15px;
  }
}

.memberMessageInfo {
  margin: 0.5rem 5px 0.5rem auto;
}

.memberActionsContainer {
  position: absolute;
  left: -45px;
  width: 42px;
  opacity: 0;
  bottom: 0px;
}

.buttonIcon {
  padding: 0 !important;
  top: -3px;
}

.myMessage {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &:hover {
    .memberActionsContainer {
      opacity: 1;
    }
  }
}

.myMessageContent {
  padding: 10px;
  border-radius: 8px;
  cursor: auto;
  ul,
  ol {
    padding-left: 15px;
  }
}

.myMessageInfo {
  margin: 0.5rem 5px 0.5rem auto;
}
// --------------------------

.messageEvent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileContainer {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  max-width: 100%;
}

.file {
  display: flex;
}

.fileInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  max-width: 87%;
  margin-bottom: 5px;

  a {
    font-size: 10px;
    line-height: normal;
    word-wrap: break-word;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.pdf {
  display: flex;
  flex-direction: column;
}
// ---------------------------

.dot {
  width: 5px;
  height: 5px;
  border: 2px solid #3ba5d7;
  border-radius: 50%;
  float: left;
  margin: 0 2px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation: fx 1000ms ease infinite 0ms;
  animation: fx 1000ms ease infinite 0ms;
}

.dot:nth-child(2) {
  -webkit-animation: fx 1000ms ease infinite 300ms;
  animation: fx 1000ms ease infinite 300ms;
}

.dot:nth-child(3) {
  -webkit-animation: fx 1000ms ease infinite 600ms;
  animation: fx 1000ms ease infinite 600ms;
}

@-webkit-keyframes fx {
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fx {
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.mentionStyle {
  color: var(--mentionUserColor);
  font-weight: bold;
  pointer-events: none;
  text-decoration: solid;
}

.separatorBG {
  opacity: 0.4;
}

.separatorText {
  background-color: transparent !important;
}
