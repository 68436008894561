.separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  &::after {
    content: "";
    flex: 1;
    opacity: 0.4;
    margin: 0 6px 0 13px;
    border-bottom: 1px solid #bec3c6;
  }

  &::before {
    margin-right: 0.5rem;
  }
}
