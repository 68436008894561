.notification {
  .bp3-toast {
    width: 385px;
    height: 56px;
    background: #e7e7e7;

    .bp3-toast-message {
      display: block;
      padding: 0;
    }
  }
  .invite {
    &.bp3-toast {
      background: #1a1a1a;
      border-radius: 8px;
      height: 70px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
    .bp3-button-group.bp3-minimal {
      justify-content: center;

      button.bp3-button {
        min-width: 16px;
        min-height: 16px;
        position: absolute;
        top: -10px;
        right: -8px;
        background: #1a1a1a;
        width: 20px;
        height: 20px;
        padding: 0;
        flex: none;
        border-radius: 50%;
      }
      a.bp3-button {
        background: #44a5d7;
        color: white;
        height: 30px;
        border-radius: 4px;
        border: none;
        min-width: 100px;
      }
    }
  }
}

.notification-content {
  display: table;
  height: 56px;
  font-size: 13px;
  color: #666;
  &.invite {
    display: grid;
    align-items: center;
    height: 70px;
    .notification-body {
      color: white;
      .notification-title {
        font-weight: 600;
      }
    }
  }
  .notification-icon {
    width: 44px;
    height: 56px;
    padding-left: 12px;
    display: table-cell;
    vertical-align: middle;
    img {
      display: block;
      width: 32px;
      height: 32px;
    }
  }
  .notification-body {
    padding: 0 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: table-cell;
    vertical-align: middle;
    color: #666;
    .notification-title {
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 600px) {
  .notification {
    .bp3-toast {
      width: 335px;
    }
  }
}

@media screen and (max-width: 320px) {
  .notification {
    .bp3-toast {
      width: 280px;
      min-width: 280px;
    }
  }
}
