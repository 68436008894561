#renderer {
  display: none;
}

.call-screen {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #474747;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #232323;
  }
}

#root [detached="true"] .in-call:not(.full-screen) .compositor-strip {
  #self_view {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    right: initial;
    video {
      border-radius: 0;
    }
    .participant-name-selfview {
      opacity: 0;
    }
  }
  .participant-tile:not(.active) {
    display: none;
  }
}

#root [detached="true"] .in-call:not(.full-screen) .remote-share .compositor-strip #remote_share {
  width: 100%;
}

#root [detached="true"] .compositor-container.strip.local-share .compositor-strip #local_share,
#root [detached="true"] .in-call:not(.full-screen) .compositor-container.strip .participant-tile.active,
#root [detached="true"] .compositor-container.strip.remote-share .compositor-strip #remote_share {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  display: block;
  z-index: initial;
}

#root
  [detached="true"]
  .in-call:not(.full-screen)
  .compositor-container.strip.local-share
  .compositor-strip
  #local_share {
  width: 100%;
}

.compositor-container {
  width: 100%;
  background: #2b2929;

  .participant-tile {
    border: 2px solid #6161615e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    background-color: #515660;

    &.active {
      border: 3px solid #6ab8d4;
      &#local_share,
      &#remote_share {
        border: 2px solid #6161615e;
      }
    }
    .video-display-name {
      text-transform: capitalize;
    }
    .video-display-name-wrapper {
      .volume-indicator {
        bottom: 3px !important;
        height: 4px !important;
        padding: 0 13px !important;
      }
    }

    &.externalStream video {
      width: 100%;
      height: 100%;
    }
    .audio-tile {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;
      .participant-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background: gray;
        border-radius: 50%;
        font-size: 28px;
        color: #efefef;
        width: 80px;
        height: 80px;
      }
    }
    .audio-tile-muted {
      display: none;
    }
    .bottom-info {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 7px;
      z-index: 1;
      .participant-name {
        padding: 2px 10px;
        border-radius: 8px;
        text-transform: capitalize;
        width: auto;
      }
      .you {
        width: auto;
        padding: 2px 10px;
        border-radius: 8px;
        text-transform: capitalize;
        margin-left: 5px !important;
      }
    }
  }

  video {
    object-fit: cover;
    border-radius: 8px;
  }

  #remote_share video,
  #local_share video {
    object-fit: contain;
  }

  .toggle-layout {
    height: 40px;
    width: 70px;
    opacity: 0.8;
    background-color: #1a1a1a;
    position: absolute;
    right: 14px;
    top: 6px;
    border-radius: 10px;
    text-align: center;
    line-height: 48px;
    .layout-mode-strip {
      height: 18px;
      width: 14px;
      display: inline-block;
      margin-right: 5px;
      background-image: url("../../../../assets/images/compositor/vertical.svg");
      background-repeat: no-repeat;
      cursor: pointer;

      &.active {
        background-image: url("../../../../assets/images/compositor/vertical_active.svg");
        &:hover {
          opacity: 1;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .layout-mode-grid {
      height: 18px;
      width: 28px;
      display: inline-block;
      margin-right: 5px;
      padding: 0;
      background-image: url("../../../../assets/images/compositor/grid.svg");
      background-repeat: no-repeat;
      cursor: pointer;

      &.active {
        background-image: url("../../../../assets/images/compositor/grid_active.svg");
        &:hover {
          opacity: 1;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .layout-mode-one {
      height: 18px;
      width: 28px;
      border-radius: 2px;
      background-color: #d6d6d6;
      display: inline-block;
      margin-right: 5px;
      background-image: url("../../../../assets/images/compositor/one_participant.svg");
    }
  }
  .compositor-strip {
    display: grid;
    height: 100%;
    padding-top: 50px;
    justify-content: right;
    align-content: start;
    background: linear-gradient(90deg, #2f2f2fe0 calc(100% - 200px), #474747 0);
    grid-gap: 5px;
    padding-right: 10px;
    z-index: 2;
    overflow-y: scroll;

    .participant-tile {
      width: 170px;
      height: 95px;
      border-radius: 8px;
      border: 2px solid #6161615e;
      background-color: #515660;

      video,
      .vidyo-rendering-container-custom,
      .video-container {
        border-radius: 8px;
      }

      &.hidden {
        display: none;
      }

      .vidyo-rendering-container-custom .video-container .video-overlay .video-display-name-wrapper {
        background: none;
      }

      .vidyo-rendering-container-custom
        .video-container
        .video-overlay
        .video-display-name-wrapper
        .video-display-name {
        opacity: 1;
      }

      .vidyo-rendering-container-custom
        .video-container
        .video-overlay
        .video-display-name-wrapper
        .video-display-name
        .video-display-name-main {
        width: fit-content;
        color: white;
        background-color: black;
        padding: 3px 8px;
        margin: 0 5px 5px 5px;
        font-size: 12px;
        border-radius: 8px;
        text-transform: capitalize;
        min-height: 14px;
        display: none;
      }
    }
    .audio-tile {
      padding: 10px;
    }
    .audio-tile-muted {
      display: none;
    }
  }

  .grid-aspect-ration-16-9 {
    align-items: center;
  }

  .compositor-grid {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 3px 3px;

    .vidyo-rendering-container-custom .video-container .video-overlay .video-display-name-wrapper {
      background: none;
    }

    .vidyo-rendering-container-custom .video-container .video-overlay .video-display-name-wrapper .video-display-name {
      opacity: 1;
    }

    .vidyo-rendering-container-custom .video-container .video-wrapper {
      border-radius: 8px;
    }

    .vidyo-rendering-container-custom
      .video-container
      .video-display-name-wrapper
      .video-display-name
      .video-display-name-main {
      flex: 1;
      bottom: 0;
      left: 0;
      width: auto;
      color: white;
      background-color: black;
      padding: 3px 8px;
      margin: 0 5px 5px 5px;
      font-size: 12px;
      border-radius: 8px;
      text-transform: capitalize;
      display: none;
    }

    .participant-tile-aspect-ratio-16-9 {
      aspect-ratio: 16/9;
    }

    @for $i from 1 through 4 {
      &.tiles-#{$i} {
        grid-template-columns: repeat($i, 1fr);
      }
    }
  }
}

.full-screen {
  .toggle-layout {
    height: 42px;
    width: 80px;
    top: 10px;
  }
  .compositor-container {
    &.strip {
      .compositor-strip {
        padding-top: 60px;
        .participant-tile {
          width: 204px;
          height: 115px;
          &.active {
            width: calc(100% - 236px);
          }
        }
      }
    }
  }
}

.compositor-container.strip .compositor-strip .participant-tile.active {
  border: none;
  width: calc(100% - 200px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.compositor-container.strip.remote-share .compositor-strip #remote_share.participant-tile,
.compositor-container.strip.local-share .compositor-strip #local_share.participant-tile {
  border: none;
  width: calc(100% - 200px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  video {
    object-fit: contain;
  }
}

.full-screen .compositor-container.strip.remote-share .compositor-strip #remote_share.participant-tile,
.full-screen .compositor-container.strip.local-share .compositor-strip #local_share.participant-tile {
  width: calc(100% - 236px);
}

.full-screen .compositor-container .compositor-strip {
  background: linear-gradient(90deg, #2f2f2fe0 calc(100% - 236px), #474747 0);
}

.compositor-container.strip.remote-share .compositor-strip .participant-tile.active,
.compositor-container.strip.local-share .compositor-strip .participant-tile.active {
  border: 3px solid #6ab8d4;
  width: 170px;
  height: 95px;
  position: relative;
  border-radius: 8px;
}

.full-screen .compositor-container.strip.remote-share .compositor-strip .participant-tile.active,
.full-screen .compositor-container.strip.local-share .compositor-strip .participant-tile.active {
  border: 3px solid #6ab8d4;
  width: 204px;
  height: 115px;
  position: relative;
}

.remote-share .compositor-strip .participant-tile.active video,
.remote-share .compositor-strip .participant-tile.active .video-container,
.local-share .compositor-strip .participant-tile.active video,
.local-share .compositor-strip .participant-tile.active .video-container {
  border-radius: 8px;
}

.footer {
  height: 10px;
}
