.emoji {
  width: 28px;
  margin: 0 3px;
  vertical-align: bottom;
}

.ql-editor {
  img {
    width: 24px;
    margin: 0 3px;
  }
}
