.dialpad-sidebar {
  flex-grow: 1;
  overflow-x: hidden !important;

  .collapse-container {
    width: 330px;
    margin: 0 auto;
    margin-bottom: 10px;
    .general-select-content .bp3-button {
      width: 275px;
    }
  }
}
