@import-normalize;
@import "./variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-resolution: 2dppx) {
  body {
    font-weight: 300;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html,
body,
#root {
  height: 100%;
  min-width: 600px;
  min-height: 600px;
  overflow: hidden;
}
body,
.radial-gradient-background {
  //   background: $radial-gradient-background;
}

// held-call: 116px high

#root div[detached="true"][heldcalls="0"] .main-sidebar .MuiDrawer-paper {
  top: 246px;
  height: calc(100% - 246px);
}

#root div[detached="true"][heldcalls="1"] .main-sidebar .MuiDrawer-paper {
  top: 362px;
  height: calc(100% - 362px);
}

#root div[detached="true"][heldcalls="2"] .main-sidebar .MuiDrawer-paper {
  top: 478px;
  height: calc(100% - 478px);
}

#root div[detached="false"][heldcalls="1"] .main-sidebar .MuiDrawer-paper {
  top: 116px;
  height: calc(100% - 116px);
}

#root div[detached="false"][heldcalls="2"] .main-sidebar .MuiDrawer-paper {
  top: 232px;
  height: calc(100% - 232px);
}
