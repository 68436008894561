.main {
  max-width: 100vw;
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-top: auto;
  margin-bottom: 2.5rem;
  width: 278px;
  height: 46px;
  padding: 0 !important;
}

.links {
  margin-bottom: 2.5rem;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mq {
  @media only screen and (min-width: 1280px) {
    max-width: 25% !important;
  }

  @media only screen and (min-width: 1920px) {
    max-width: 18% !important;
  }
}
