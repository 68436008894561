.call-status-message-container {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  .call-status-message {
    .only-participant {
      text-align: center;
      .title {
        font-size: 26px;
        color: hsla(0, 0%, 75%, 1);
      }
      .description {
        font-size: 18px;
        color: hsla(0, 0%, 50%, 1);
      }
    }
  }
}
