#root [detached="true"] .dial-out-call-container {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  z-index: initial;
  width: 375px;
  height: 246px;
}

#root div[detached="true"][heldcalls="1"] .dial-out-call-container {
  top: 116px;
}

#root div[detached="true"][heldcalls="2"] .dial-out-call-container {
  top: 232px;
}

#root [detached="true"] .dial-out-call-container .header,
#root div[detached="true"] .dial-out-call-container .footer .dial-controls .speaker,
#root div[detached="true"] .dial-out-call-container .footer .dial-controls .expand,
#root div[detached="true"] .dial-out-call-container .footer .dial-controls .dialpad,
#root div[detached="true"] .dial-out-call-container .footer .dial-controls .settings {
  display: none;
}

#root div[detached="true"] .dial-out-call-container .footer .dial-controls .transfer,
#root div[detached="true"] .dial-out-call-container .footer .dial-controls .hold,
#root div[detached="true"] .dial-out-call-container .footer .dial-controls .go-back {
  display: block;
}

.dial-out-call-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  background-color: #2f3138;

  &:hover {
    .dial-controls {
      transform: translateY(0);
      visibility: visible;
    }
  }

  &.full-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    z-index: 1200;

    .header {
      display: none;
    }
    .toggle.expand {
      background-size: 22px;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
    }
  }
  .call-status-message {
    color: white;
  }
  .header {
    height: 60px;
    .status {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 1em;
      .text {
        padding: 0 10px;
      }
    }
  }
  .call-held-anchor {
    position: relative; // anchor for call-held

    :nth-child(1) {
      // first rectangle
      top: 20px;
    }

    :nth-child(2) {
      // second rectangle
      top: 60px;
    }

    .call-held-rectangle {
      position: absolute; // anchored in relative parent
      left: 20px;
      display: flex;
      width: fit-content;
      padding: 5px 5px 5px 5px;

      .call-held-text {
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }

  .call-section {
    display: flex;
    flex-direction: column;
    justify-items: center;
    flex: 1;
    background-color: #2b2929;
    .call-status {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: white;
    }
    .call-connected {
      display: grid;
      grid-template-rows: 1fr;
      align-items: center;
      height: 100%;
      grid-gap: 3px;
    }
    .conference-connected {
      padding: 3px 3px 3px 3px;
      display: grid;
      grid-auto-rows: 1fr;
      align-items: center;

      gap: 3px;
      row-gap: 3px;

      video {
        display: none;
      }
    }
    .rows-1 {
      height: 100%;
    }
    .tile {
      border: 2px solid #6161615e;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: #515660;
      border-radius: 8px;
      &.active {
        border: 2px solid #2191e0;
      }
      video {
        display: none;
      }
      .name {
        position: absolute;
        width: auto;
        color: #fff;
        background-color: #000;
        opacity: 0.7;
        padding: 3px 5px;
        left: 5px;
        font-size: 12px;
        bottom: 5px;
        border-radius: 2px;
        text-transform: capitalize;
      }
      .number {
        position: absolute;
        width: auto;
        color: #fff;
        background-color: #000;
        opacity: 0.7;
        padding: 3px 5px;
        right: 5px;
        font-size: 12px;
        bottom: 5px;
        border-radius: 2px;
        text-transform: capitalize;
      }
    }
  }
  .footer {
    height: 120px;
    background: #f6f7fb;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: transparent;
  }
  .footer[detached="false"] {
    height: 120px;
  }
  .footer[detached="true"] {
    height: 80px;
  }
}

.dial-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7px;
  position: relative;
  top: 20px;
}

.dial-controls {
  background-color: #1a1a1a;
  display: flex;
  flex: 0 0 42px;
  flex-direction: column;
  padding: 6px;
  border-radius: 8px;
  align-items: center;
  background-size: 30px;
  width: max-content;
  transform: translateY(100px);
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);

  .dial-controls-buttons {
    display: flex;
    gap: 6px;
  }

  .dial-controls-label {
    color: white;
    margin-top: 3px;
  }

  button.toggle {
    height: 42px;
    width: 42px;
    padding: 0;
    border: none;
    outline: none;
    &:hover,
    &.active {
      background-color: hsla(0, 0%, 10%, 1);
      box-shadow: 0px 0px 0px 1px grey;
    }
    &.go-back {
      display: none;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .dial-out-call-container {
    &.full-screen {
      background-color: #303030 !important;
    }
  }
}

.conference-connected {
  height: 100%;
  .tile {
    // aspect-ratio: unset !important;
    height: 100%;
  }
}

.call-connected,
.conference-connected {
  .participant-tile-aspect-ratio-16-9 {
    aspect-ratio: 16/9;
    height: auto;
  }
}

.compositor-grid {
  @for $i from 1 through 4 {
    &.tiles-#{$i} {
      grid-template-columns: repeat($i, 1fr);
    }
  }
}
