* {
  padding: 0;
  margin: 0;
}

.content-center {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.pulse i {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.pulse {
  height: 70px;
  width: 70px;
  --main-bg-color: #f6f7fb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pulse::before {
  content: "";
  position: absolute;
  border: 1px solid var(--main-bg-color);
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}

.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid var(--main-bg-color);
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
