#root div[detached="false"][activecall="true"] .chat-content {
  margin-top: calc((100vh - 64px) * 0.6);
}

#root div[detached="true"] {
  .in-call {
    width: 375px;
    height: 246px !important;
    min-height: 246px !important;
    position: fixed;
    left: 0;
    top: 0 !important;
    z-index: 1200;
    display: flex;
    overflow-x: auto;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
    .call-screen {
      min-height: 246px;
      .header {
        display: block;
      }
    }
    .call-status-message-container {
      .title {
        font-size: 14px;
      }
      .description {
        font-size: 12px;
      }
    }
    &:hover {
      .call-controls {
        visibility: visible;
      }
    }
  }
  .in-call-self-view {
    display: none;
  }
  .call-controls {
    visibility: hidden;
    button.toggle,
    button.end-call-button,
    .share-button {
      display: none;
    }
    .toggle.microphone,
    .toggle.go-back {
      display: block;
    }
  }
}

#root div[outcall="true"] .full-screen .call-controls {
  visibility: visible;
  button.toggle,
  button.end-call-button,
  .share-button {
    display: block;
  }
  .toggle.go-back {
    display: none;
  }
  .toggle.microphone {
    display: block;
  }
}

#root div[detached="true"] .full-screen,
#root div[outcall="true"] .full-screen,
.full-screen {
  &.in-call {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
    position: fixed;
    left: 0;
    top: 0 !important;
    z-index: 1200;
  }
  &.call-screen {
    min-height: 100vh;
  }
}

.in-call {
  width: 100%;
  height: calc(100vh - 72px);
  min-height: calc(100vh - 72px);
  max-height: calc(100vh - 72px);
  position: fixed;
  left: 0px;
  top: 72px !important;
  z-index: 1200;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  @media (min-width: 900px) {
    max-width: calc(100vw - 375px);
    left: 375px;
  }
}
.call-screen {
  width: 100%;
  height: 100%;
  min-height: 437px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  background: #242424;
  display: flex;
  justify-content: space-between;

  &:hover {
    .call-controls {
      transform: translateY(0);
      visibility: visible;
    }
  }

  .header {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    flex: 0 0 48px;
    display: none;
    .header-section-right {
      display: flex;
      justify-content: flex-end;
      flex: 1 1 auto;
    }
    .room-name {
      height: 24px;
      color: white;
      padding: 3px 0 3px 15px;
    }
  }

  .render-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    #renderer {
      width: 100%;
      height: 100%;
    }
    .self-view {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .snackbar-container {
    display: flex;
    flex: 0 0 42px;
    flex-direction: column;
    padding: 6px;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 80px;
    margin: auto;
    width: 100%;
  }
  .call-controls {
    background-color: #1a1a1a;
    display: flex;
    flex: 0 0 42px;
    flex-direction: column;
    padding: 6px;
    border-radius: 8px;
    align-items: center;
    background-size: 30px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin: auto;
    width: max-content;
    transform: translateY(100px);
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    z-index: 2;

    .call-controls-buttons {
      height: 45px;
      display: flex;
      gap: 6px;
    }

    .call-controls-label {
      color: white;
      margin-top: 3px;
    }

    button.toggle {
      height: 42px;
      width: 42px;
      padding: 0;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: hsla(0, 0%, 10%, 1);
        box-shadow: 0px 0px 0px 1px grey;
      }
      &.go-back {
        display: none;
      }
    }
  }
}

@media screen and (min-height: 100px) and (max-height: 600px) {
  #root div[detached="false"][activecall="true"] .chat-content {
    margin-top: calc((100vh - 64px) * 0.6);
  }
  .call-screen {
    min-height: 237px;
  }
}

@media screen and (min-height: 600px) and (max-height: 750px) {
  #root div[detached="false"][activecall="true"] .chat-content {
    margin-top: calc((100vh - 64px) * 0.6);
  }
  .call-screen {
    min-height: 337px;
  }
}

.conference-status-container {
  position: absolute;
  margin: auto;
  width: calc(100% - 200px);
  text-align: center;
  padding-top: 10px;
  margin-left: 100px;
  z-index: 1000;
}

.in-call.chat-screen {
  height: calc((100vh - 64px) * 0.6);
  min-height: calc((100vh - 64px) * 0.6);
}
