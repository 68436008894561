.dialpad-container {
  .dialer {
    width: 261px;
    margin: 0 auto;
    margin-bottom: 20px;
    .dialer-input {
      position: relative;
      .delete {
        transition: 0.3s;
        height: 24px;
        &:hover {
          //           box-shadow: 1px 1px 2px #9191918f;
          cursor: pointer;
          transform: scale(1.1);
        }
        &[disabled] {
          pointer-events: none;
          opacity: 0.8;
        }
      }
    }
    .dialer-numpad {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      justify-items: center;
      grid-gap: 10px;
      margin-top: 20px;
      margin-bottom: 10px;
      padding: 0 15px;
      .numpad-button {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: relative;
        text-align: center;
        transition: 0.3s;
        user-select: none;
        &:hover,
        &.pressed {
          box-shadow: 1px 1px 2px #9191918f;
          cursor: pointer;
          transform: scale(1.1);
        }
        [data-value="+"] {
          transition: 0.3s;
          &:hover {
            transform: scale(1.5);
          }
        }
        [data-value="0"] {
          transition: 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }
        .top {
          padding-top: 4px;
        }

        .bottom {
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
    .dialer-actions {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-items: center;
      .call {
        width: 56px;
        height: 56px;
        transition: 0.3s;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        &:hover,
        &.pressed {
          box-shadow: 1px 1px 2px #9191918f;
          cursor: pointer;
          transform: scale(1.1);
        }
        &[disabled] {
          pointer-events: none;
          opacity: 0.8;
        }
      }
    }
  }
}
