.call-notification-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  .transfer-call {
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 20px;
    text-decoration: underline;
    padding: 12px 0px;
    &:hover {
      cursor: pointer;
    }
  }
  .media-settings {
    display: flex;
    justify-content: center;
    padding-top: 21px;
  }
}
