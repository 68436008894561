.main {
  width: 100%;
  height: 100vh;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message {
  margin-top: 2rem;
}

.retryValidate {
  color: white;
  background-color: red;
}

.error {
  color: red;
  &:hover {
    background-color: red !important;
  }
}
