.settingsContainer {
  padding: 0 24px;
  .section {
    width: 100%;

    .separator {
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;

      &::after {
        content: "";
        flex: 1;
        opacity: 0.4;
        margin-left: 0.7rem;
        border-bottom: 1px solid #d8d8e8;
      }

      &::before {
        margin-right: 0.5rem;
      }

      span {
        height: 19px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
      }
    }

    .titleContainer {
      margin-bottom: 20px;
    }

    .deviceContainer {
      padding: 0 16px;

      .selfViewContainer {
        padding-bottom: 20px;

        .self-view {
          width: 100%;
          height: 160px;
          border-radius: 10px;

          .video-tile {
            border-radius: 10px;
          }
        }
      }
    }
  }

  .device-select-container .camera-select .select-list-header {
    margin-top: 0;
  }

  .device-select-container {
    margin-bottom: 10px;
    .select-list-header {
      margin-bottom: 16px;
      margin-top: 20px;
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
    }

    .general-select-content .bp3-button {
      width: 280px;
      border-radius: 10px;
      height: 36px;
      border: none;
      background-color: rgba(142, 142, 147, 0.1);
    }

    #microphone-select {
      margin-bottom: 10px;
    }
  }
}

.sidebar-container-setting .deviceSettings {
  .device-select-container {
    width: 348px;
    #microphone-select {
      margin-bottom: 20px;
    }
  }

  .self-view {
    width: 280px;
  }
}
