.main {
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 100%;
  flex-grow: 1;
  max-width: 100vw;
  @media (min-width: 900px) {
    max-width: calc(100vw - 375px);
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0px !important;
  height: calc(100vh - 64px);
  min-height: calc(100vh - 64px);
}

.buttonIcon {
  width: 46px;
  height: 46px;
  span {
    img {
      width: 15px;
      height: 15px;
    }
  }
}

.roomFooterEmojiPicker {
  position: absolute;
  bottom: 56px;
  right: 0;
  div > em-emoji-picker {
    position: relative;
    z-index: 1201;
  }
}

.roomHeaderContent {
  flex-grow: 1;
  padding: 0 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.roomHeaderSub {
  font-size: 0.8rem;
  opacity: 0.6;
}

.messageForm {
  width: 100%;
  margin: 0 5px;
}

.messageInput {
  min-height: 38px;
  font-size: 0.9rem;
  border-radius: 4px;
  word-break: break-word;

  @media (prefers-color-scheme: dark) {
    .ql-toolbar {
      filter: invert(1) !important;
    }
  }
}

.callActionContainer {
  position: relative;
}

.precallSettingsContainer {
  z-index: 1200;
  &::before {
    width: 0;
    height: 0;
    display: block;
    content: "";
    border-style: solid;
    border-width: 0 14px 18px 14px;
    border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    opacity: 0.9200000167;
    position: absolute;
    left: 380px;
    top: -18px;
  }
  position: absolute;
  top: 60px;
  left: -370px;
}

.mentionsMemberList {
  position: absolute;
  width: 100%;
  bottom: 60px;
  height: auto;
  max-width: 96%;
  padding: 1em;
  max-height: 300px;
  overflow: auto;

  .mentionsMember {
    display: flex;
    align-items: center;
    margin: 1em 0;
    cursor: pointer;
    span {
      margin-left: 0.5em;
    }
  }
}
