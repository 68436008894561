@keyframes append-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.edit-message-wrapper {
  position: relative;
  border-top: 1px solid var(--inputBorderColor);
  border-left: 1px solid var(--inputBorderColor);
  border-right: 1px solid var(--inputBorderColor);
  padding: 5px 5px 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--inputBackgroundColor);
  cursor: auto;
  overflow: hidden;
  .header {
    display: flex;
    h3 {
      font-size: 0.9rem;
      padding: 4px;
      font-weight: bold;
    }
    svg {
      padding: 4px 0;
    }
  }
  .close {
    position: absolute;
    right: 8px;
    top: 8px;
    color: inherit;
    cursor: pointer;
  }
  .message-body {
    max-height: 150px;
    overflow: auto;
    /* Basics/Body 2 */
    font-family: "Noto Sans", "Helvetica", "Tahoma", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.edit-message-wrapper + #msgInput {
  .quill-focus {
  }
  .ql-toolbar {
    display: none;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow.quill-focus {
    border-top: 1px solid transparent !important;
  }
  .show-toolbar {
    display: block;
    border-left: 1px solid var(--inputBorderColor);
    border-right: 1px solid var(--inputBorderColor);
    border-top: 1px solid transparent;
    background-color: var(--inputBackgroundColor);
  }
  .ql-editor {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 1px solid transparent;
    padding-top: 5px;
  }
}

.emoji-mart {
  overflow: hidden;
}

#msgInput {
  .ql-container.ql-snow {
    background-color: var(--inputBackgroundColor);
  }

  .ql-snow .ql-editor .mentionStyle {
    color: var(--mentionUserColor);
    font-weight: bold;
    pointer-events: none;
    text-decoration: solid;
  }
}
