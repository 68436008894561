.dialpad {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
  .dialpad-container .dialer {
    width: 100%;
    margin-bottom: 5px;
  }
  .dialpad-container .dialer .dialer-input {
    .MuiInputBase-root {
      height: 36px;
    }
  }
  .dialpad-container .dialer .dialer-numpad {
    width: 100%;
    padding: 0px 54px;
    margin-top: 41px;
  }
}

.transferOptionsOuterSelectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
}

.transferOptionsSelectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  gap: 3px;
}

.contactsContainer {
  // 5px padding in contactUserAccordion
  // for border stretching out of 20px/20px
  // limit
  margin: 0 10px;
}

.contactsList {
  padding-top: 10px;
  max-height: 475px;
}

.transferActionsContainer {
  width: 100%;
  padding: 15px 8px 8px 8px;
}

.callTransferActionsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactUserIconEntry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.contactUserIconEntryText {
  cursor: default;
}

.chatContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: calc(100% - 6px);
  padding: 0px;
  #message_event_content {
    background-color: RGBA(183, 183, 183, 0.9);
    color: black;
  }
  .MuiToolbar-gutters {
    padding: 0px 5px;
  }
  .container {
    padding: 0px 0px;
  }
  .MuiIconButton-root.Mui-disabled {
    background-color: transparent;
  }
  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  #message_typing {
    background-color: rgba(26, 26, 26, 0.88) !important;
  }
}

.chatContentDark {
  #message_typing {
    background-color: rgba(66, 66, 66, 1) !important;
  }
}

.chatTitleComponent {
  height: 45px;
  width: 100%;
}

.chatContactUser {
  align-items: center;
  cursor: pointer;
  left: 60px;
  height: 45px;
  display: flex;
}

.chatContactUserIcons {
  #callTransferActionsContainer {
    padding: 0;
    gap: 0;
  }
}
