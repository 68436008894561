#root [heldcalls="1"] .call-on-hold-container {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  z-index: initial;
  width: 375px;
  height: 116px;
}

#root [heldcalls="2"] .call-on-hold-container {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  z-index: initial;
  width: 375px;
  height: 232px;
}

.call-on-hold-entry {
  width: 100%;
  height: 116px;
  left: 0px;
  top: 0px;
  mix-blend-mode: normal;
  opacity: 0.92;
  backdrop-filter: blur(25px);
}

.column-container {
  display: flex;
  flex-flow: row;
}

.column-text {
  margin: auto 0;
  font-weight: 500;
  line-height: normal;
}

.column-icon {
  margin: auto 20px auto auto;
}
