.filterContainer {
  padding: 0 10px;
  height: 25px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.filterItem {
  margin: 0 3px !important;
  width: fit-content;
  min-width: 50px;
  max-width: 90px;
  min-height: 25px;
  font-size: 0.7rem !important;
  cursor: pointer;
}
