.extension {
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: right;
  font-size: 13px;
  right: 20px;
  top: 22px;
}
.extensionStatus {
  display: inline-block;
}

.extensionSvg {
  display: inline-block;
  margin-right: 5px;
}

.extensionSvg.unregistered {
  fill: rgba(228, 44, 44, 1);
  vertical-align: baseline;
}

.extensionSvg.registered {
  fill: rgba(0, 198, 94, 1);
  vertical-align: baseline;
}
