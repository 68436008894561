.header {
  padding: 20px;
  max-height: 80px;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.headerButtons {
  width: 40px !important;
  min-width: 40px !important;
  height: 40px;
  min-height: 40px;
}

.status {
  margin-left: 14px;
}

//content
.sideBarContent {
  flex-grow: 1;
  overflow-x: hidden !important;
}

.noRoomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
  height: 100%;
}

.checkmark {
  margin-left: auto;
}

.brightnessIcons {
  margin-right: 4px;
}

.link {
  text-decoration: none;
  padding: 0;
}

//
.hide {
  display: none !important;
  visibility: hidden;
}

//
.addContactHeader {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    display: flex;
    align-items: center;
  }

  .filterContacts {
    display: flex;
  }

  p {
    font-size: 2rem;
  }
}

.contactUser {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.contactUserInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}

.contactUserPresence {
  opacity: 0.6;
  text-transform: capitalize;
}

// Meeting UI

.sideBarHeaderDiv {
  display: flex;
}

.sideBarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 25px;

  .sideBarTitle {
    padding: 0;
    min-height: inherit;
    max-height: inherit;
  }

  .addBtn {
    width: 35px !important;
    min-width: 35px !important;
    height: 35px;
    min-height: 35px;
  }
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduleSection {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .meetingListItem {
    padding: 20px 20px 0 10px;
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .dateSection {
      width: 38px;
      margin-right: 14px;
      margin-top: 4px;
    }

    .date {
      height: 36px;
      width: 38px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .day {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        margin-top: -3px;
        font-feature-settings:
          "tnum" on,
          "lnum" on;
      }
    }

    .meetingDetails {
      display: flex;
      flex-direction: column;
      text-align: start;
      flex-grow: 1;
      height: 100%;
      max-width: 290px;

      .title {
        font-weight: 600;

        span {
          word-break: break-word;
        }
      }

      .time {
        margin-bottom: 1em;
      }

      .border {
        flex-grow: 1;
        margin-top: 16px;
        border-bottom: 1px solid #c8cdd0;
        width: 215px;
      }
    }
  }
}

.settingsLink {
  display: flex;
  justify-content: left !important;
  flex-direction: row;

  &:hover {
    text-decoration: none;

    .brightness {
      filter: brightness(2.7) !important;
    }
  }
}

.settingsActiveRoute {
  .label {
    color: #fff !important;
  }

  .description {
    color: #fff !important;
  }

  .brightness {
    filter: brightness(2.7) !important;
  }
}
