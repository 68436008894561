.participants {
  height: 100vh;
  margin-top: 25px;
  .title {
    display: flex;
    align-items: center;
    margin: 0 20px 30px;
    font-size: 16px;
  }
  .list {
    margin-left: 20px;
    padding-bottom: 10px;
    .participant {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      .avatar {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: grey;
        margin-right: 15px;
        color: white;
        text-align: center;
        line-height: 34px;
      }
    }
  }
}
