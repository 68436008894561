.self-view {
  box-sizing: border-box;
  height: 126px;
  width: 224px;
  border: 1px solid #b0b0b0;
  background-color: #464646;
  color: #b0b0b0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .no-permission {
    background-color: black;
    text-align: center;
    font-size: 11px;
    padding: 0 20px;
    color: white;
  }

  .self-view-render {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1px;

    .spinner {
      position: absolute;
    }

    video.video-tile {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &.user,
      &.desktop {
        transform: rotateY(180deg);
      }
      pointer-events: none;
    }

    .external-controls-container {
      background: rgba(51, 51, 51, 0.5);
      position: absolute;
      top: 0;
      right: 0;
      width: 58px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
