.adhoc-notification-container {
  width: 400px;
  height: 156px;
  position: absolute;
  top: 70px;
  right: 20px;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #00000021;
  display: grid;
  grid-template-columns: 1fr 130px;
  opacity: 0.95;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(25px);

  .adhoc-notification-content {
    margin: auto 16px;
    font-size: 13px;
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
  .adhoc-notification-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 16px auto 0;
  }
}
