// ROOM CATEGORIES
.categories {
  padding: 0 20px;
  height: 25px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.category {
  margin: 0 3.5px;
  width: fit-content;
  min-width: 50px;
  min-height: 25px;
  background-color: #2f3138 !important;
  color: #fff !important;
  font-size: 0.7rem !important;
  cursor: pointer;
}

.activeCategory {
  background-color: #3ba5d7 !important;
}

// ROOM LIST
.expandIcon {
  height: 25px;
  width: 25px;
  background-color: #2f3138;
  color: #fff;
  border-radius: 50%;
}

.summary {
  min-height: 48px !important;
  height: 48px !important;
}

.details {
  padding: 0 0 0 0 !important;
  display: flex;
  flex-direction: column;
}

.room {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  .moreMenu {
    position: absolute;
    right: 15px;
  }

  &:hover {
    .moreMenu {
      opacity: 1;
    }
  }
}

.link {
  text-decoration: none !important;
  padding: 0;
}

.roomInfo {
  margin-left: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  &::after {
    content: "";
    flex: 1;
    opacity: 0.6;
    margin-left: 0.7rem;
    border-bottom: 1px solid #d8d8e8;
  }

  &::before {
    margin-right: 0.5rem;
  }

  span {
    height: 19px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
}

.moreMenu {
  opacity: 0;
  margin-left: auto;
}

.hide {
  display: none !important;
  visibility: hidden;
}
