.participants-list-container {
  width: 354px;
  position: absolute;
  left: 8px;
  bottom: 8px;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  height: calc(100% - 16px);
  min-height: calc(100% - 16px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 3;

  .participants-list-header {
    height: auto;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: none;
    background: none;
    text-align: center;
  }

  .participants-list-content {
    overflow-y: auto;
    padding-bottom: 15px;
    max-height: calc(100vh - 107px);
    margin-right: 1px;
  }

  .list-item {
    width: 100%;
    padding: 5px 10px 5px 21px;
    .list-item-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .list-item-avatar-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .avatar {
      display: block;
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      font-size: 17px;
      background-color: #aaa;
      border-radius: 50%;
      font-weight: 300;
    }

    .additional-info {
      margin-top: 5px;
      min-width: 0;
      display: flex;
      flex-direction: row;
    }
  }
}
