.test-sound-container {
  display: inline-flex;
  align-items: center;
  .play-button {
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    &:hover {
      transform: scale(1.2);
    }
  }
}
