* {
  outline: none !important;
}

// html, body {
//     overflow: hidden;
// }

#root {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0 !important;
  position: relative;
}

p {
  margin: 0 !important;
}

a {
  color: var(--linkColor);
}

.grey-icon {
  opacity: 0.5;
  cursor: pointer;
}

.toggler {
  position: absolute;
  top: 1em;
  left: 1em;
}

.scroll-bar {
  $background: transparent;

  overflow: overlay !important;

  // &:hover {
  //   overflow-x: hidden;
  //   overflow-y: auto !important;
  //   overflow: overlay !important;
  // }
  /* total width */
  &::-webkit-scrollbar {
    // @include background($theme);
    background-color: $background;
    width: 5px;
    height: 5px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    // @include background($theme);
    background-color: $background;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: $background;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 5px;
    border: 5px solid $background;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid $background;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
}

.ql-toolbar.ql-snow {
  visibility: hidden;
  border: none;
}

.ql-container.ql-snow {
  border: 0;
  border-radius: 4px;
}

.ql-editor {
  border-radius: 4px;
  border: 1px solid var(--inputBorderColor);
  max-height: 50px;
  $background: transparent;
  overflow: hidden !important;
  padding: 8px 10px;

  font-family: "Noto Sans", "Helvetica", "Tahoma", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  &:hover {
    overflow-x: hidden;
    overflow-y: auto !important;
    overflow: overlay !important;
  }
  /* total width */
  &::-webkit-scrollbar {
    // @include background($theme);
    background-color: $background;
    width: 5px;
    height: 5px;
    cursor: pointer !important;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    // @include background($theme);
    background-color: $background;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: $background;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 5px;
    border: 5px solid $background;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid $background;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
}

.ql-toolbar {
  .ql-formats {
    button {
      width: 24px !important;
      @media (max-width: 760px) {
        width: 20px !important;
      }
    }
  }
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: var(--inputPlaceholderColor);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-top: 1px;
}

.show-toolbar {
  visibility: visible !important;
}

.ql-formats {
  margin-top: 2px !important;
}

// .ql-toolbar.ql-snow + .ql-container.ql-snow.quill-focus {
//   border-top: 2px solid #c8cdd0;
// }

.ql-container.ql-snow.quill-focus {
  border: 1px solid var(--inputBorderColor);
  border-top: 1px solid var(--inputBorderColor) !important;
  border-bottom: 1px solid var(--inputBorderFocusColor);
  overflow: hidden;
  $background: transparent;
  .ql-editor {
    border: 0 !important;
  }
}

.ql-syntax {
  background: black !important;
  color: white !important;
  padding: 10px;
}

blockquote {
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 16px;
  border-radius: 8px;
}

.p2p_video_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.p2p_video_container_muted {
  display: none;
}

#local_video_render,
#remote_video_render {
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;

  height: auto !important;
  max-height: 100% !important;
  min-height: 100% !important;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.speaker-select {
  margin-top: 15px;
}

.deviceSettings {
  .self-view {
    margin-top: 1em;
    width: 70%;
    height: 70%;
  }

  .self-view-render {
    width: 100% !important;
    padding-top: 56.25% !important;
    height: 0px !important;
    position: relative !important;
  }

  .spinner {
    top: 50%;
  }

  .self-view,
  video {
    border-radius: 10px;
    border: none;
  }

  video {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

  .general-select-content .bp3-button {
    width: 300px;
  }

  .bp3-select-list div,
  .bp3-select-list div button {
    width: 90% !important;
  }
}

body {
  scrollbar-width: thin; /* "auto" or "thin" */
}

.room-info-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.room-members-wrapper {
  overflow-y: auto !important;
}

.networkMessageBar {
  min-width: 100vw;
  width: 100%;
  min-height: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in;
}

.networkMessage {
  margin: 0 auto !important;
}

.online {
  background-color: green;
}

.offline {
  background-color: red;
}

.hidden {
  display: none !important;
}

.show-container {
  .ql-toolbar {
    filter: invert(0.3);
  }
}

.ql-tooltip.ql-flip {
  display: none !important;
}

.roomInfoName {
  max-width: 230px;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 2px;
}

.lb-icon-download {
  background-image: url("../assets/images/buttons/download.svg");
  filter: invert(1);
}

.matrixCallNotificationContainer {
  position: absolute;
  bottom: 72px;
  right: 20px;
  z-index: 100;

  .matrixCallNotification {
    min-width: 270px;
    min-height: 100px;
    width: a;
    display: flex;
    flex-direction: column;
    padding: 1em;
  }

  .matrixCallNotificationInfo {
    margin: 1em 0;
    padding: 0 1em;
    display: flex;
  }

  .matrixCallNotificationInfoUser {
    span {
      opacity: 0.7;
      font-size: 0.9em;
    }
  }

  .matrixCallNotificationButtons {
    display: flex;
    flex-direction: row;

    button {
      width: 120px;
      margin: 0 1em;
    }

    .rejectButton {
      background-color: red;
      color: #fff;
    }
  }
}

.callNotificationContainer {
  z-index: 1200;
  position: absolute;
  top: 0px;
  left: 0px;
}

.userInfo {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userInfoName {
  max-width: 230px;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.userInfoStatus {
  opacity: 0.6;
}

@media (prefers-color-scheme: dark) {
  .quill > .ql-container > .ql-editor.ql-blank::before {
    color: white;
  }

  .Mui-disabled {
    color: gray !important;
  }
}
