.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  margin: auto;
}
